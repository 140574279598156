.TrackCard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: gray;
    color: #fff;
}
.TrackBtn{
    padding: 10px;
    display: flex;
    align-items: center;
}
.TrackBtn input{
    margin-right: 10px;
    transform: scale(1.3);
}
.TrackBtn label{
    text-transform: uppercase;
    font-size: 13px;
}

.MinW500{
    min-width: 500px;
}
/* card style start */
.TrackDetails.RequestDetails .CardCount{
    position: static;
    justify-content: end;
    margin-left: auto;
    width: auto;
    border-radius: 20px;
    background: transparent;
    color: orangered;
}
.RequestDetails .TrackMessageCard h4{
    font-weight: bold;
}
.RequestDetails .TrackMessageCard h4 span{
    font-weight: 400;
    font-size: 13px;
}
.RequestDetails .TrackMessageCard h4 span img{
    width: 24px;
}
.TrackMessageCard:has(.RequestModalBtn){
    text-align: end;
    position: relative;
    margin-top: 35px;
}
.RequestModalBtn{
    position: absolute;
    right: -10px;
    bottom: 0;
    background-color: orangered;
    color: #fff;
    padding: 10px 18px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 105px;
    border: none;
    text-wrap: nowrap;
}.RequestModalBtn_new{
    
    background-color: orangered;
    color: #fff;
    padding: 10px 18px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 105px;
    border: none;
    text-wrap: nowrap;
}.rate_btn_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}.rate_btn_box.rate_btn_box1{
    justify-content: flex-start;
}
.RequestModalContain{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000060;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
}
.RequestModal{
    background-color: #fff;
    max-width: 350px;
    width: 100%;
    border-radius: 10px;
    padding: 20px 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.RequestModal .CardTitle{
   text-align: center;
   position: relative;
   margin: 0;
   padding-right: 20px;
}
.RequestModal .CardTitle .CloseBtn{
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: transparent;
    border: none;
}
.RequestModal ul {
    list-style: none;
    margin: 0;
    padding: 0 ;
    padding-top: 20px;
}
.RequestModal ul li a{
    background-color: orangered;
    color: #fff;
    font-size: 13px;
    display: block;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 20px;
}
/* card style end */


@media(max-width:370px){
    .TrackCard{
        flex-wrap: wrap;
        padding: 10px 7px;
    }
    .TrackBtn{
        padding: 5px;
        text-wrap: nowrap;
    }
    .TrackBtn input{
        margin-right: 5px;
        transform: scale(1.1);
    }
}

.modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.123); 
    z-index: 9999; 
  }
  
  .modal1 {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px; 
    text-align: center;
    z-index: 10000; 
  }
  
  .modal-overlay-background1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  
  .button1 {
    margin: 5px;
    padding: 8px 15px;
    font-size: 12px;
    cursor: pointer;
    border: none;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
  }
  
  .button1:hover {
    background-color: #45a049;
  }
  .star_rate{
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .RequestModalBtn_new.RequestModalBtn {
    position: absolute;
    left: -16px;
    bottom: 0;
    background-color: orangered;
    color: #fff;
    padding: 10px 18px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 105px;
    border: none;
    text-wrap: nowrap;
  }
  .button1.button1_close{
    background-color: orangered;
  }
  