.ForgotPswrdContain{
    background-color: #fff;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}
.ForgotPswrdContain::before{
    content: '';
    position: fixed;
    bottom: -70px;
    left: -70px;
    width: 160px;
    height: 160px;
    border-radius: 50pc;
    background-color: orangered;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
}
.ForgotPswrdContain::after{
    content: '';
    position: fixed;
    top: -70px;
    right: -70px;
    width: 160px;
    height: 160px;
    border-radius: 50pc;
    background-color: orangered;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
}
.ForgotPswrdLogo{
    max-width: 500px;
    width: 100%;
    padding-left: 2px;
    margin: auto;
}
.ForgotPswrdLogo img{
    width: 60px;
}
.ForgotPswrdCard{
    max-width: 500px;
    width: 100%;
    margin-top: 40px;
    padding: 40px;
    position: relative;
    margin: auto;
}
.ForgotPswrdTitle{
    margin-bottom: 50px;
}
.ForgotPswrdTitle h4{
    font-size: 33px;
    color: orangered;
    text-align: center;
}
.ForgotPswrdTitle p{
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: gray;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.ForgotPswrdGroup{
    margin-bottom: 15px;
}
.ForgotPswrdGroup label,.ForgotPswrdGroup.CheckBox a{
    color: orangered;
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin-bottom: 2px;
}
.ForgotPswrdGroup label{
    position: relative;
    display: inline-block;
}
.ForgotPswrdGroup input{
    width: 100%;
    font-size: 13px;
    outline: none;
    border: none;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ForgotPswrdGroup input::placeholder{
    color: rgb(143, 142, 142);
}
.ForgotPswrdBtn{
    text-align: center;
}

.ForgotPswrdGroup button{
    border: none;
    background-color: orangered;
    color: #fff;
    padding: 10px 30px;
    border-radius: 10px;
    width: 150px;
    text-transform: uppercase;
    margin: auto;
}

.ForgotPswrdFooter{
    background-color: #f5f5f5;
    color: #000;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

}
.ForgotPswrdFooter p{
    margin: 0;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000;
    text-align: center;
    text-transform: uppercase;
}
.ForgotPswrdFooter p span{
    color: orangered;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ForgotPswrdGroup .PasswrdInpt{
    position: relative;
}
.ForgotPswrdGroup .PasswrdInpt button{
    border: none;
    background: transparent;
    position: absolute;
    padding: 0;
    top: 10px;
    right: 10px;
}
.ForgotPswrdGroup .PasswrdInpt button img{
    width: 18px;
}
.ForgotPswrdGroup.CheckBox {
    margin-bottom: 0;
}
.ForgotPswrdDivider{
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    text-align: center;
}
.ForgotPswrdDivider::before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: rgb(201, 201, 201);
}
.ForgotPswrdDivider span{
    background-color: #fff;
    position: relative;
    z-index: 1;
    padding: 0 10px;
}
.ForgotPswrdGroup.signUp{
    text-align: center;
}
.ForgotPswrdGroup.signUp p{
    font-size: 13px;
}
.ForgotPswrdGroup.signUp a{
    color: orangered;
    font-size: 14px;
    font-weight: 500;
}
@media(min-width:500px){
    .ForgotPswrdGroup button{
        border-radius: 10px;
    }
    .ForgotPswrdBtn{
        position: static;
        text-align: center;
    }
}