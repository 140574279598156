.userProfileView{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userProfileView img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;

}
.userProfileView h2{
font-size: 16px;

}.userProfileView h2 span{
    font-size: 14px;
    font-weight: 400;

}.modal-overlay.modal_overlay1{
    background-color: rgb(0, 0, 0);
    padding: 0 30px;
}
.modal_overlay1.modal-overlay img{
width: 100%;
max-height: 200px;
}img.close_btn_icon1.close_btn_icon{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: -200px;
   
}

#Header1{
    background-color: orangered;
    color: #fff;
    padding: 20px 10px 40px;
    height: 70px;
}

.ContentContainer1{
    /* background-color: #fff; */
    /* padding: 15px 20px; */
    /* border-radius: 20px; */
    margin-top: -20px;
    margin-bottom: 57px;
    overflow: auto;
    max-height: calc(100vh - 132px);
}
.modal_overlay1 img{
    width: 100%;
    max-height: 300px;
}.modal_overlay1{
    display: flex
    ;
        justify-content: center;
        align-items: center;
        height: 80vh;
    
}