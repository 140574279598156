/* #Header.ContactHeader{
    padding: 20px;
} */
.Contact_content{
    padding: 10px;
}
.Contact_content .Pcontent_card.Note{
    padding: 20px 0;
    background-color: #faf0f0;;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.Contact_content .Pcontent_card h4{
    padding: 20px 0;
}
.Contact_content .Pcontent_card p{
    margin-left: 10px;
}
.Pcontent_card p img{
    width: 20px;
    margin-right: 15px;
}
.Contact_content .Pcontent_card.Note p{
    letter-spacing: 1px;
}