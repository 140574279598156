
/* footer sytle start */
#Footer{
    background-color: #fff;
    padding: 8px 7px 7px;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
position: fixed;
bottom: 0;
left: 0;
width: 100%;
z-index: 101;
height: 57px;
}
.Footer_card{
    width: 100%;
    text-align: center;
}
.Footer_cion{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* border-radius: 10px; */
    gap: 2px;
    /* width: 82px; */
}
.Footer_cion img{
    width: 18px;
}
.Footer_cion .BotIcon{
    width: 22px;
}
.Footer_cion.active{
    transition: .4s;
    padding-bottom: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 2px solid orangered;
    
}
/* .Footer_cion.active img{
    filter: invert();
} */
.Footer_cion span{
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}
/* footer style end */


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.chat_count.chat_count1{
    position: absolute;
    margin-left: 40px;
    margin-top: -3px;
    padding: 1px 5px;
    border-radius: 50%;
    font-size: 10px;
    color: #ff4500;
    border: 1px solid #ff4500;
    font-weight: bold;
}