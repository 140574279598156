/* Loader.css */
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh; 
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px; /* Width of the spinner */
    height: 50px; /* Height of the spinner */
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }