
/* profile page style start */
.ProfileHeader{
    justify-content: space-between;
}
.ProfileHeader .bg_transparant{
    background: transparent;
}
.ProfileHeader .bg_transparant img{
    filter: invert();
    width: 20px;
}

.Profile_image{
    width: 80px;
    height: 80px;
    margin: auto;
    position: relative;
}
.ImgP {
    width: 100px;
    height: 100px; 
    border-radius: 50%; 
    overflow: hidden; 
    border: 1px solid #dbd5d5;
}

.ImgP img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Profile_image .EditProfile{
    position: absolute;
    right: -30px;
    bottom: -12px;
    border: none;
    outline: none;
    background: transparent;
}
.Profile_image .EditProfile img {
    width: 20px;
}
.Pcontent_card h4{
    font-size: 14px;
    margin-bottom: 5px;
    
}
.Pcontent_card p{
    font-size: 12px;
    margin: 0;
}
.CmpnyTitle{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 22px;
}

/* accordian style start */
.PasswordChange{
    padding-top: 20px;
}
.PasswordChange .accordion{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.PasswordChange .accordion-button:not(.collapsed),.PasswordChange .accordion-button.collapsed{
    background-color: gray;
    color: #fff;
}
.PasswordChange .accordion-button:focus{
    box-shadow: none;
}
.PasswordChange .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon);
}
.PasswordChange .accordion-button::after{
    filter: invert();
}
.PasswordChange .accordion-button:not(.collapsed){
box-shadow: none;
}
/* accordian style end */
.Pcontent_card input,.Pcontent_card input::placeholder{
    color: #000;
    font-size: 13px;
}
.Pcontent_card input{
    padding: 5px 10px;
    width: 100%;
    border: 1px solid gray;
    border-radius: 5px;
    outline: none;
}
.Pcontent_card:has(.PasswordChangebtn){
    text-align: center;
}
.PasswordChangebtn{
    background-color: rgb(18, 184, 235);
    color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    outline: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 150px;
    margin: auto;
}
.PinputContain{
    position: relative;
}
.ShowPasswordbtn{
    border: none;
    position: absolute;
    right: 10px;
    bottom: 6px;
    background: transparent;
}
.ShowPasswordbtn img{
    width: 20px;
}

.spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  /* new css to add profile */
  .Pcontent_card1 label{
    font-size: 14px;
    margin-bottom: 5px;
    padding: 5px 10px;
  }

  .Pcontent_card11 label{
    font-size: 14px;
    margin-bottom: 5px;
    padding: 5px 10px;
  }
  
  .Pcontent_card1 input{
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    border: 1px solid #fff
  }

  .Pcontent_card1 input:focus{
    outline: none;
  }
  
  .Pcontent_card11 input{
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid grey;
  }
  
  .Pcontent_card11 input:focus{
    border-color:  grey ;
  }

  .editBtn button{
    background-color: #08A0BB;
    color: white;
    font-size: 13px;
    width: 90px;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
  }

  .myBtn{
    margin-right: 10px;
  }

  /* .editBtn button{
    background-color: #08A0BB;
    color: white;
    font-size: 13px;
    width: 90px;
    border: none;
} */

/* profile page style end */