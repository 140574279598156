.sent_date_message{
    font-weight: 500;
    font-size: 12px;
}
.employee-name.emp_name{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
 } 
  
 .employee-info.employee-info_w{
    width: 100%;
 }

 .employee-last-message.emp_unread{
   display: flex;
   justify-content: space-between;
   align-items: baseline;
 }

 .unRead_msg{
   color: red;
   font-size: 14px;
 }
 /* .employee-last-message.employee-last-message_n{
    height: initial;
 } */